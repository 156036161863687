import {
  InAppType,
  miniInAppTypes,
  ofAInAppTypes,
} from "@/api/inapps/typedefs/inAppTypes";
import { ProductLine } from "@/lib/productLine";

export function getInAppTypeOptions(productLine: ProductLine): InAppType[] {
  if (productLine.productName === "Mini") {
    return miniInAppTypes;
  } else {
    return ofAInAppTypes;
  }
}
