
import { defineComponent, PropType, toRefs } from "vue";
import { LocationQuery, onBeforeRouteUpdate } from "vue-router";

import InAppsFilters from "@/components/inapps/list/InAppsFilters.vue";
import InAppsHeader from "@/components/inapps/list/InAppsHeader.vue";
import InAppsList from "@/components/inapps/list/InAppsList.vue";
import { mapParamsRouteGuard } from "@/router/mapParamsRouteGuard";

import { useInApps } from "./useInApps";

export default defineComponent({
  components: {
    InAppsHeader,
    InAppsList,
    InAppsFilters,
  },
  props: {
    product: {
      type: Number,
      required: true,
    },
    productLine: {
      type: Number,
      required: true,
    },
    queryParams: {
      type: Object as PropType<LocationQuery>,
      required: true,
    },
  },
  setup(props) {
    onBeforeRouteUpdate(mapParamsRouteGuard);
    return useInApps(toRefs(props));
  },
});
