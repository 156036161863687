import { InAppFilters } from "@/api/inapps/typedefs/inApp";
import { downloadCsv } from "@/lib/csv";
import {
  buildOrderingQueryParamsFromPagination,
  Pagination,
} from "@/lib/pagination";
import { appendQueryParams } from "@/lib/urlBuilder";

import { buildInAppQueryParamsFromFilters } from "./filters";

export async function downloadInAppListAsCsv(
  productLine: number,
  filters: InAppFilters,
  pagination: Pagination
): Promise<void> {
  const queryParams = {
    ...buildInAppQueryParamsFromFilters(filters),
    ...buildOrderingQueryParamsFromPagination(pagination),
  };

  const path = `/api/product-lines/${productLine}/inapps-csv`;
  const url = appendQueryParams(path, queryParams);

  downloadCsv(url);
}
