import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref: "dialogRef",
    "data-test": "ia.typedialog",
    onHide: _ctx.onDialogHide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "dialog-card q-dialog-plugin" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_form, { onSubmit: _ctx.onSubmit }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "text-h5" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Pick In App type")
                ])),
                _: 1
              }),
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_select, {
                    modelValue: _ctx.inAppType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inAppType) = $event)),
                    "data-test": "ia.typedialog.select",
                    dense: "",
                    clearable: "",
                    filled: "",
                    options: _ctx.inAppTypeOptions,
                    label: "Type",
                    disable: _ctx.isLoading,
                    "lazy-rules": "",
                    rules: [
              (val) => (val && val.length > 0) || 'In App type is required',
            ]
                  }, null, 8, ["modelValue", "options", "disable", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    label: "Cancel",
                    disable: _ctx.isLoading,
                    onClick: _ctx.onDialogHide
                  }, null, 8, ["disable", "onClick"]),
                  _createVNode(_component_q_btn, {
                    label: "OK",
                    disable: _ctx.isLoading,
                    type: "submit",
                    color: "primary",
                    "data-test": "ia.typedialog.button.ok"
                  }, null, 8, ["disable"])
                ]),
                _: 1
              }),
              _createVNode(_component_q_inner_loading, { showing: _ctx.isLoading }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_spinner, {
                    size: "50px",
                    color: "primary"
                  })
                ]),
                _: 1
              }, 8, ["showing"])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}