
import { defineComponent, PropType, toRefs } from "vue";

import { InAppFilters, InAppStatus, InAppType } from "@/api/inapps";
import { fetchTags } from "@/api/tags/list";
import ListFilters from "@/components/shared/list/ListFilters.vue";
import TagSelect from "@/components/shared/tags/TagSelect.vue";

import { useInAppsFilters } from "../useInAppsFilters";

export default defineComponent({
  components: {
    ListFilters,
    TagSelect,
  },
  props: {
    filters: {
      type: Object as PropType<InAppFilters>,
      required: true,
    },
    createdByOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    statusOptions: {
      type: Array as PropType<InAppStatus[]>,
      default: () => [],
    },
    typeOptions: {
      type: Array as PropType<InAppType[]>,
      default: () => [],
    },
  },
  emits: {
    filtersChange: (payload: InAppFilters) => typeof payload === "object",
  },
  setup(props, { emit }) {
    return {
      ...useInAppsFilters({
        ...toRefs(props),
        onFiltersChange: (filters) => emit("filtersChange", filters),
      }),
      fetchTags,
    };
  },
});
