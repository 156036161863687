import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed, Ref, ref, watch } from "vue";
import { LocationQuery, useRouter } from "vue-router";

import {
  getInAppTypeOptions,
  InAppMetadata,
  statusOptions,
} from "@/api/inapps";
import { buildInAppFiltersFromQueryParams } from "@/api/inapps/filters";
import { downloadInAppListAsCsv } from "@/api/inapps/listDownloadCsv";
import { fetchInAppUsers } from "@/api/inapps/users";
import { useInAppTypeDialog } from "@/components/inapps/dialogs/useInAppTypeDialog";
import { DataState } from "@/composables/typedefs";
import { domUtils } from "@/lib/domUtils";
import { getInAppTypeId } from "@/lib/inapps/inAppType";
import { buildPaginationFromQueryParams, Pagination } from "@/lib/pagination";
import { getProductLine } from "@/lib/productLine";

import { useInAppPermissions } from "./useInAppPermissions";
import { useInAppsList } from "./useInAppsList";

const DEFAULT_IN_APPS_PAGINATION: Partial<Pagination> = {
  sortBy: "id",
  descending: true,
};
const MESSAGE_ROW_SEPARATOR = "\t";

interface UseInAppsOptions {
  productLine: Ref<number>;
  product: Ref<number>;
  queryParams: Ref<LocationQuery>;
}

export function useInApps({
  product,
  productLine,
  queryParams,
}: UseInAppsOptions) {
  const router = useRouter();
  const $q = useQuasar();

  const initialPagination = buildPaginationFromQueryParams(
    queryParams.value,
    DEFAULT_IN_APPS_PAGINATION
  );

  const {
    state,
    inApps,
    pagination,
    onPaginationChange,
    filters,
    onFiltersChange,
  } = useInAppsList({
    productLine,
    initialFilters: buildInAppFiltersFromQueryParams(queryParams.value),
    initialPagination,
  });

  const isLoading = computed(() => state.value === DataState.LOADING);
  const isError = computed(() => state.value === DataState.ERROR);

  const { openDialog: openInAppTypeDialog } = useInAppTypeDialog({
    onOk: (inAppType) => {
      const inAppTypeId = getInAppTypeId(inAppType);
      router.push({
        // TODO(PNS-1724): Use route name when implemented.
        path: `/inapps/create-message/${product.value}/${productLine.value}/${inAppTypeId}`,
      });
    },
  });

  const onAddNewInApp = () => {
    openInAppTypeDialog(getProductLine(productLine.value));
  };

  const onOpenExistingInApp = (inApp: InAppMetadata) => {
    // TODO(PNS-1724): Use route name when implemented.
    router.push({
      path: `/inapps/message/${product.value}/${productLine.value}/${inApp.id}`,
    });
  };

  const onOpenExistingInAppInNewTab = (inApp: InAppMetadata) => {
    // TODO(PNS-1724): Use route name when implemented.
    domUtils
      .getWindow()
      .open(
        `/inapps/message/${product.value}/${productLine.value}/${inApp.id}`,
        "_blank"
      );
  };

  const onClipboardCopyInAppRow = (inApp: InAppMetadata) => {
    const contentArray = [
      inApp.id,
      inApp.status,
      inApp.name,
      inApp.inAppType,
      inApp.createdBy,
      inApp.modifiedBy ?? "",
      inApp.createdAt,
      inApp.modifiedAt ?? "",
      inApp.isRuntime,
    ];

    const content = contentArray.join(MESSAGE_ROW_SEPARATOR);

    copyToClipboard(content).catch(() => {
      $q.notify({
        type: "negative",
        message: "Copy to clipboard failed",
      });
    });
  };

  const onExportToCsv = () =>
    downloadInAppListAsCsv(productLine.value, filters.value, pagination.value);

  const createdByOptions = ref<string[]>([]);
  watch(
    [productLine],
    async () => {
      const users = await fetchInAppUsers(productLine.value);
      createdByOptions.value = users.map((user) => user.username).sort();
    },
    { immediate: true }
  );

  const { permissions } = useInAppPermissions({
    productLineId: productLine,
  });

  const inAppTypeOptions = computed(() =>
    getInAppTypeOptions(getProductLine(productLine.value))
  );

  return {
    product,
    productLine,
    // Event handlers.
    onOpenExistingInApp,
    onOpenExistingInAppInNewTab,
    onAddNewInApp,
    onClipboardCopyInAppRow,
    onExportToCsv,
    // Data.
    inApps,
    isLoading,
    isError,
    // Pagination.
    pagination,
    onPaginationChange,
    // Filters.
    filters,
    onFiltersChange,
    // Options.
    statusOptions,
    createdByOptions,
    inAppTypeOptions,
    // Permissions
    permissions,
  };
}
