import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-py-sm top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_indicator = _resolveComponent("error-indicator")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true),
      _renderSlot(_ctx.$slots, "right-panel", {}, undefined, true)
    ]),
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_error_indicator, {
          key: 0,
          message: _ctx.errorMessage
        }, null, 8, ["message"]))
      : _renderSlot(_ctx.$slots, "table", { key: 1 }, undefined, true)
  ], 64))
}