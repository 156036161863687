import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_list, {
    dense: "",
    style: {"min-width":"100px"}
  }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        "data-test": "row.contextmenu.open"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { onClick: _ctx.handleOpenClick }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Open")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })), [
        [_directive_close_popup]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        "data-test": "row.contextmenu.open-tab"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { onClick: _ctx.handleOpenNewTabClick }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Open in new tab")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })), [
        [_directive_close_popup]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_item, {
        clickable: "",
        "data-test": "row.contextmenu.copy"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { onClick: _ctx.handleCopyToClipboardClick }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Copy to clipboard")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })), [
        [_directive_close_popup]
      ])
    ]),
    _: 1
  }))
}