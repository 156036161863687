import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "in-apps q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_in_apps_header = _resolveComponent("in-apps-header")!
  const _component_in_apps_filters = _resolveComponent("in-apps-filters")!
  const _component_in_apps_list = _resolveComponent("in-apps-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_in_apps_header, {
      "add-button-visible": _ctx.permissions.hasAddInAppPermission,
      onAddNewInApp: _ctx.onAddNewInApp
    }, null, 8, ["add-button-visible", "onAddNewInApp"]),
    _createVNode(_component_in_apps_filters, {
      "created-by-options": _ctx.createdByOptions,
      "status-options": _ctx.statusOptions,
      "type-options": _ctx.inAppTypeOptions,
      filters: _ctx.filters,
      onFiltersChange: _ctx.onFiltersChange
    }, null, 8, ["created-by-options", "status-options", "type-options", "filters", "onFiltersChange"]),
    _createVNode(_component_in_apps_list, {
      pagination: _ctx.pagination,
      "is-loading": _ctx.isLoading,
      "is-error": _ctx.isError,
      "in-apps": _ctx.inApps,
      onPaginationChange: _ctx.onPaginationChange,
      onRowClick: _ctx.onOpenExistingInApp,
      onRowMiddleClick: _ctx.onOpenExistingInAppInNewTab,
      onRowMenuOpenClick: _ctx.onOpenExistingInApp,
      onRowMenuOpenNewTabClick: _ctx.onOpenExistingInAppInNewTab,
      onRowMenuClipboardCopyClick: _ctx.onClipboardCopyInAppRow,
      onExportToCsvClick: _ctx.onExportToCsv
    }, null, 8, ["pagination", "is-loading", "is-error", "in-apps", "onPaginationChange", "onRowClick", "onRowMiddleClick", "onRowMenuOpenClick", "onRowMenuOpenNewTabClick", "onRowMenuClipboardCopyClick", "onExportToCsvClick"])
  ]))
}