import * as t from "io-ts";

import {
  PRODUCT_LINES_MINI,
  PRODUCT_LINES_OFA,
  ProductLine,
} from "@/lib/productLine";

// Types
export type InAppType = t.TypeOf<typeof InAppTypeGuard>;

// Constants
export const miniInAppTypes: InAppType[] = [
  "Alert",
  "WebView Panel",
  "Center Dialog",
  "Bottom Sheet",
  "Confirm",
  "Maintenance Action",
  "Config Bundle Confirm",
  "Wallpaper Sheet Basic",
];
export const ofAInAppTypes: InAppType[] = [
  "Alert",
  "Sheet",
  "Confirm",
  "Wallpaper Sheet",
  "Config Bundle Sheet",
  "Subscription Sheet",
  "Start Page Card",
  "Rate Dialog",
];

export function getAvailableProductLines(inAppType: InAppType): ProductLine[] {
  const availableProductLines: ProductLine[] = [];
  if (miniInAppTypes.includes(inAppType)) {
    availableProductLines.push(...PRODUCT_LINES_MINI);
  }

  if (ofAInAppTypes.includes(inAppType)) {
    availableProductLines.push(...PRODUCT_LINES_OFA);
  }

  return availableProductLines;
}

// GUARDS
// InApp.type
export const InAppTypeGuard = t.keyof({
  Alert: t.literal("Alert"),
  Sheet: t.literal("Sheet"),
  "WebView Panel": t.literal("WebView Panel"),
  "Bottom Sheet": t.literal("Bottom Sheet"),
  Confirm: t.literal("Confirm"),
  "Maintenance Action": t.literal("Maintenance Action"),
  "Center Dialog": t.literal("Center Dialog"),
  "Wallpaper Sheet": t.literal("Wallpaper Sheet"),
  "Wallpaper Sheet Basic": t.literal("Wallpaper Sheet Basic"),
  "Config Bundle Sheet": t.literal("Config Bundle Sheet"),
  "Config Bundle Confirm": t.literal("Config Bundle Confirm"),
  "Subscription Sheet": t.literal("Subscription Sheet"),
  "Start Page Card": t.literal("Start Page Card"),
  "Rate Dialog": t.literal("Rate Dialog"),
});

export const RolloutStrategyGuard = t.keyof({
  "Day match only": t.literal("Day match only"),
  Gradual: t.literal("Gradual"),
});

export type RolloutStrategyType = t.TypeOf<typeof RolloutStrategyGuard>;

export const rolloutStrategyOptions = Object.keys(
  RolloutStrategyGuard.keys
) as RolloutStrategyType[];
