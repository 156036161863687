import camelcaseKeys from "camelcase-keys";

import { InAppListItemGuard, InAppMetadata } from "@/api/inapps/typedefs/inApp";
import { httpClient, QueryParams } from "@/lib/http";
import { cast } from "@/lib/typing";

export function convertInApp(data: unknown): InAppMetadata {
  if (typeof data !== "object") {
    throw new Error(`Invalid InApp data type to convert - ${data}`);
  }
  // TODO(PNS-1738): Drop when BE will start sending camelCase.
  const camelCasedData = camelcaseKeys({ ...data });
  return cast(InAppListItemGuard, camelCasedData);
}

export function convertInApps(data: unknown): InAppMetadata[] {
  if (!Array.isArray(data)) return [];
  return data.map((inApp) => convertInApp(inApp));
}

export async function fetchInApps(
  productLine: number,
  queryParams?: QueryParams
): Promise<{ data: InAppMetadata[]; count: number }> {
  const response = await httpClient.get(
    `/api/product-lines/${productLine}/inapps/`,
    queryParams
  );
  const responseBody = await response.json();
  // NOTE: When there is pagination, backend returns paginated items at 'results' attribute.
  // Otherwise it returns array of all items.
  const data = responseBody["results"] ?? responseBody;
  const count = responseBody["count"] ?? responseBody?.length ?? 0;
  return { data: convertInApps(data), count };
}
