
import { defineComponent } from "vue";

import RowContextMenuContent from "./RowContextMenuContent.vue";

export default defineComponent({
  components: {
    RowContextMenuContent,
  },
  inheritAttrs: false,
});
