import useQuasar from 'quasar/src/composables/use-quasar.js';;

import { getInAppTypeOptions, InAppType } from "@/api/inapps";
import { InAppTypeGuard } from "@/api/inapps/typedefs/inAppTypes";
import { ProductLine } from "@/lib/productLine";
import { cast } from "@/lib/typing";

import InAppTypeDialog from "./InAppTypeDialog.vue";

interface InAppTypeDialogOptions {
  onOk: (inAppType: InAppType) => void;
}

export function useInAppTypeDialog(options: InAppTypeDialogOptions) {
  const $q = useQuasar();

  const openDialog = (productLine: ProductLine) => {
    const inAppTypeOptions = getInAppTypeOptions(productLine);

    $q.dialog({
      component: InAppTypeDialog,
      componentProps: { inAppTypeOptions },
    }).onOk((payload: unknown) => {
      const rawInAppType = (payload as { inAppType?: unknown }).inAppType;
      const inAppType = cast(InAppTypeGuard, rawInAppType);
      options.onOk(inAppType);
    });
  };

  return {
    openDialog,
  };
}
