import { Ref } from "vue";

import { Tag } from "@/api/tags/typedefs";

export const getUpdateSingleTagListener =
  (tagIdRef: Ref<number | null>) =>
  (tag: Tag[] | Tag | null): void => {
    if (Array.isArray(tag)) {
      throw new Error(
        "You must use <tag-select> component with single-select-mode = true"
      );
    }
    if (!tag) {
      tagIdRef.value = null;
    } else {
      tagIdRef.value = tag.id;
    }
  };
