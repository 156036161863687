const ID_FILTER_VALUES_SEPARATOR = ",";
const ID_FILTER_RANGE_VALUE_SEPARATOR = "-";

function isValidRangeFilterSingleValueFormat(value: string): boolean {
  if (value === "") return false;
  const parsedValue = Number(value);
  return Number.isInteger(parsedValue);
}

function isValidRangeFilterRangeValueFormat(value: string): boolean {
  const items = value.split(ID_FILTER_RANGE_VALUE_SEPARATOR);
  if (items.length !== 2) return false;

  const [rawRangeStart, rawRangeEnd] = items;
  if (
    !isValidRangeFilterSingleValueFormat(rawRangeStart) ||
    !isValidRangeFilterSingleValueFormat(rawRangeEnd)
  ) {
    return false;
  }

  return Number(rawRangeStart) <= Number(rawRangeEnd);
}

function stripTrailingComma(value: string): string {
  if (value.slice(-1) === ",") {
    return value.slice(0, -1);
  } else {
    return value;
  }
}

export function isValidRangeFilterFormat(rawValue: unknown): boolean {
  if (typeof rawValue !== "string") {
    return false;
  }

  const value = stripTrailingComma(rawValue);

  const items = value.split(ID_FILTER_VALUES_SEPARATOR);

  for (const item of items) {
    const isValid =
      isValidRangeFilterRangeValueFormat(item) ||
      isValidRangeFilterSingleValueFormat(item);

    if (!isValid) {
      return false;
    }
  }

  return true;
}
