import * as t from "io-ts";

// GUARDS
export const InAppDisplayWhenGuard = t.type({
  // TODO(PNS-2552): Declare and use JsonLogicGuard here.
  triggers: t.unknown,
  // TODO(PNS-2552): Declare and use JsonLogicGuard here.
  limits: t.unknown,
});

// TYPES
export type InAppDisplayWhen = t.TypeOf<typeof InAppDisplayWhenGuard>;
