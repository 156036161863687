import { ref, Ref, watch } from "vue";
import { useRouter } from "vue-router";

import { fetchInApps, InAppFilters, InAppMetadata } from "@/api/inapps";
import { buildInAppQueryParamsFromFilters } from "@/api/inapps/filters";
import { DataState } from "@/composables/typedefs";
import { buildQueryParamsFromPagination, Pagination } from "@/lib/pagination";

interface InAppListDataOptions {
  productLine: Ref<number>;
  initialPagination: Pagination;
  initialFilters: InAppFilters;
}

export function useInAppsList(options: InAppListDataOptions) {
  const router = useRouter();
  const inApps: Ref<InAppMetadata[]> = ref([]);
  const pagination = ref<Pagination>(options.initialPagination);
  const filters = ref<InAppFilters>(options.initialFilters);

  const onPaginationChange = async (newPagination: Pagination) => {
    pagination.value = newPagination;
    await tryToFetchInApps();
  };

  const onFiltersChange = async (newFilters: InAppFilters) => {
    filters.value = newFilters;
    await tryToFetchInApps();
  };

  const state: Ref<DataState> = ref(DataState.INITIAL);

  const tryToFetchInApps = async () => {
    state.value = DataState.LOADING;
    const queryParams = {
      ...buildQueryParamsFromPagination(pagination.value),
      ...buildInAppQueryParamsFromFilters(filters.value),
    };
    try {
      const { data, count } = await fetchInApps(
        options.productLine.value,
        queryParams
      );
      inApps.value = data;
      pagination.value.rowsNumber = count;
      router.push({ query: queryParams });
      state.value = DataState.LOADED;
    } catch (error) {
      state.value = DataState.ERROR;
      throw error;
    }
  };

  watch([options.productLine], () => tryToFetchInApps());

  return {
    inApps,
    state,
    pagination,
    onPaginationChange,
    filters,
    onFiltersChange,
  };
}
