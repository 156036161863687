// TODO(PNS-2180): Extract to shared library.
import * as t from "io-ts";

import {
  InAppDataAlertGuard,
  InAppDataBottomSheetGuard,
  InAppDataCenterDialogGuard,
  InAppDataConfigBundleConfirmGuard,
  InAppDataConfigBundleSheetGuard,
  InAppDataConfirmGuard,
  InAppDataMaintenanceActionGuard,
  InAppDataSheetGuard,
  InAppDataStartPageCardGuard,
  InAppDataSubscriptionSheetGuard,
  InAppDataWallpaperSheetBasicGuard,
  InAppDataWallpaperSheetGuard,
  InAppDataWebviewPanelGuard,
} from "@/api/inapps/typedefs/inAppData";
import {
  InAppType,
  InAppTypeGuard,
  RolloutStrategyGuard,
} from "@/api/inapps/typedefs/inAppTypes";
import { TagGuard } from "@/api/tags/typedefs";
import { TargetUnified } from "@/api/target/typedefs";

import { InAppDisplayWhenGuard } from "./inAppDisplayWhen";

// Types
export type InAppMetadata = ExistingInApp | InAppListItem;

export type InAppTarget = TargetUnified;

export type InAppStatus = t.TypeOf<typeof InAppStatusGuard>;
export type NewInApp = t.TypeOf<typeof NewInAppGuard>;
export type InAppListItem = t.TypeOf<typeof InAppListItemGuard>;
export type ExistingInApp = t.TypeOf<typeof ExistingInAppGuard>;
// `NewOrExistingInApp` (also `NewInApp` and `ExistingInApp`) does not check if
// inApp.inAppType && inApp.inAppData[].type matches.
export type NewOrExistingInApp = t.TypeOf<typeof NewOrExistingInAppGuard>;
export type InAppData = t.TypeOf<typeof InAppDataGuard>;
export type InAppAlert = t.TypeOf<typeof InAppAlertGuard>;
export type InAppConfirm = t.TypeOf<typeof InAppConfirmGuard>;
export type InAppSheet = t.TypeOf<typeof InAppSheetGuard>;
export type InAppBottomSheet = t.TypeOf<typeof InAppBottomSheetGuard>;
export type InAppMaintenanceAction = t.TypeOf<
  typeof InAppMaintenanceActionGuard
>;
export type InAppWallpaperSheet = t.TypeOf<typeof InAppWallpaperSheetGuard>;
export type InAppWallpaperBasicSheet = t.TypeOf<
  typeof InAppWallpaperSheetBasicGuard
>;
export type InAppDataCenterDialog = t.TypeOf<typeof InAppDataCenterDialogGuard>;
export type InAppSubscriptionSheet = t.TypeOf<
  typeof InAppSubscriptionSheetGuard
>;
export type InAppStartPageCard = t.TypeOf<typeof InAppDataStartPageCardGuard>;
// `InApp` type enforces proper equal type in fields
// inApp.inAppType && inApp.inAppData[].type, allows both new / existing in app.
export type InApp = t.TypeOf<typeof InAppGuard>;

// Interfaces
export interface InAppFilters {
  name: string | null;
  status: InAppStatus | null;
  id: string | null;
  inAppType: InAppType | null;
  createdBy: string | null;
  tag: number | null;
}

// Enums
export enum InAppPriority {
  ZERO = "Zero",
  HIGH = "High",
  LOW = "Low",
}

// Functions
export function isExistingInApp(
  inApp: NewOrExistingInApp
): inApp is ExistingInApp {
  return (inApp as ExistingInApp).id !== undefined;
}

// GUARDS

// InApp status
export const InAppStatusGuard = t.keyof({
  Draft: null,
  Deleted: null,
  Pending: null,
  Active: null,
  Paused: null,
  Cancelled: null,
  Failed: null,
  Finished: null,
});
export const statusOptions = Object.keys(
  InAppStatusGuard.keys
) as InAppStatus[];

// InApp priority
export const InAppPriorityGuard = t.keyof({
  [InAppPriority.ZERO]: null,
  [InAppPriority.HIGH]: null,
  [InAppPriority.LOW]: null,
});

// InApp inAppData
export const InAppDataGuard = t.union([
  InAppDataAlertGuard,
  InAppDataConfirmGuard,
  InAppDataSheetGuard,
  InAppDataWebviewPanelGuard,
  InAppDataBottomSheetGuard,
  InAppDataMaintenanceActionGuard,
  InAppDataWallpaperSheetGuard,
  InAppDataWallpaperSheetBasicGuard,
  InAppDataConfigBundleSheetGuard,
  InAppDataConfigBundleConfirmGuard,
  InAppDataCenterDialogGuard,
  InAppDataSubscriptionSheetGuard,
  InAppDataStartPageCardGuard,
]);

export const LocalizedInAppDataGuard = t.union([
  InAppDataAlertGuard,
  InAppDataConfirmGuard,
  InAppDataSheetGuard,
  InAppDataWebviewPanelGuard,
  InAppDataBottomSheetGuard,
  InAppDataMaintenanceActionGuard,
  InAppDataWallpaperSheetGuard,
  InAppDataWallpaperSheetBasicGuard,
  InAppDataConfigBundleSheetGuard,
  InAppDataConfigBundleConfirmGuard,
  InAppDataCenterDialogGuard,
  InAppDataSubscriptionSheetGuard,
  InAppDataStartPageCardGuard,
]);

// InApp
const InAppBaseGuard = t.type({
  name: t.string,
  isRuntime: t.union([t.boolean, t.null]),
  forceNonRuntime: t.boolean,
  inAppType: InAppTypeGuard,
  priority: InAppPriorityGuard,
  startTime: t.union([t.string, t.null]),
  endTime: t.union([t.string, t.null]),
  campaignId: t.union([t.number, t.null]),
});

export const InAppListItemGuard = t.intersection([
  InAppBaseGuard,
  t.interface({
    id: t.number,
    status: InAppStatusGuard,
    affectedUsers: t.union([t.number, t.null]),
    createdBy: t.string,
    createdAt: t.string,
    modifiedBy: t.union([t.string, t.null]),
    modifiedAt: t.union([t.string, t.null]),
  }),
]);

export const NewInAppGuard = t.intersection([
  InAppBaseGuard,
  t.interface({
    displayWhen: InAppDisplayWhenGuard,
    target: t.union([t.number, t.null]),
    tags: t.array(TagGuard),
    rolloutStrategyType: t.union([RolloutStrategyGuard, t.null]),
    rolloutStrategyWindowSize: t.union([t.number, t.null]),
  }),
  t.partial({
    inAppData: t.array(InAppDataGuard),
  }),
]);

export const ExistingInAppGuard = t.intersection([
  NewInAppGuard,
  InAppListItemGuard,
]);

export const NewOrExistingInAppGuard = t.union([
  NewInAppGuard,
  ExistingInAppGuard,
]);

export const InAppDataPartial = t.type({
  inAppData: t.array(InAppDataGuard),
});

export type InAppDataPartial = t.TypeOf<typeof InAppDataPartial>;

// InApp types
export const InAppAlertGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys.Alert,
    inAppData: t.array(InAppDataAlertGuard),
  }),
]);

export const InAppConfirmGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys.Confirm,
    inAppData: t.array(InAppDataConfirmGuard),
  }),
]);

export const InAppSheetGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys.Sheet,
    inAppData: t.array(InAppDataSheetGuard),
  }),
]);

export const InAppWebviewPanelGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["WebView Panel"],
    inAppData: t.array(InAppDataWebviewPanelGuard),
  }),
]);

export const InAppBottomSheetGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Bottom Sheet"],
    inAppData: t.array(InAppDataBottomSheetGuard),
  }),
]);

export const InAppMaintenanceActionGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Maintenance Action"],
    inAppData: t.array(InAppDataMaintenanceActionGuard),
  }),
]);

export const InAppWallpaperSheetGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Wallpaper Sheet"],
    inAppData: t.array(InAppDataWallpaperSheetGuard),
  }),
]);

export const InAppWallpaperSheetBasicGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Wallpaper Sheet Basic"],
    inAppData: t.array(InAppDataWallpaperSheetBasicGuard),
  }),
]);

export const InAppConfigBundleSheetGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Config Bundle Sheet"],
    inAppData: t.array(InAppDataConfigBundleSheetGuard),
  }),
]);

export const InAppConfigBundleConfirmGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Config Bundle Confirm"],
    inAppData: t.array(InAppDataConfigBundleConfirmGuard),
  }),
]);

export const InAppCenterDialogGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Center Dialog"],
    inAppData: t.array(InAppDataCenterDialogGuard),
  }),
]);

export const InAppSubscriptionSheetGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Subscription Sheet"],
    inAppData: t.array(InAppDataSubscriptionSheetGuard),
  }),
]);

export const InAppStartPageCardGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Start Page Card"],
    inAppData: t.array(InAppDataStartPageCardGuard),
  }),
]);

export const InAppRateDialogGuard = t.intersection([
  NewOrExistingInAppGuard,
  t.type({
    inAppType: InAppTypeGuard.keys["Rate Dialog"],
  }),
]);

// End of InApp types
export const InAppGuard = t.union([
  InAppAlertGuard,
  InAppConfirmGuard,
  InAppSheetGuard,
  InAppWebviewPanelGuard,
  InAppBottomSheetGuard,
  InAppMaintenanceActionGuard,
  InAppWallpaperSheetGuard,
  InAppWallpaperSheetBasicGuard,
  InAppConfigBundleSheetGuard,
  InAppConfigBundleConfirmGuard,
  InAppCenterDialogGuard,
  InAppSubscriptionSheetGuard,
  InAppStartPageCardGuard,
  InAppRateDialogGuard,
]);
