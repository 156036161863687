
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;
import { defineComponent, PropType, Ref, ref } from "vue";

import { InAppType } from "@/api/inapps";

export default defineComponent({
  props: {
    inAppTypeOptions: {
      type: Array as PropType<InAppType[]>,
      required: true,
    },
  },
  emits: Array.from(useDialogPluginComponent.emits),
  setup() {
    const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
    const isLoading = ref(false);
    const inAppType: Ref<InAppType | null> = ref(null);

    const onSubmit = () => {
      isLoading.value = true;
      onDialogOK({ inAppType: inAppType.value });
    };

    return {
      isLoading,
      inAppType,
      onDialogHide,
      dialogRef,
      onSubmit,
    };
  },
});
