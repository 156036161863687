import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_row_context_menu_content = _resolveComponent("row-context-menu-content")!
  const _component_q_menu = _resolveComponent("q-menu")!

  return (_openBlock(), _createBlock(_component_q_menu, {
    "touch-position": "",
    "context-menu": "",
    "data-test": "row.contextmenu"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_row_context_menu_content, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16)
    ]),
    _: 1
  }))
}