
import { defineComponent } from "vue";

import ErrorIndicator from "@/components/shared/ErrorIndicator.vue";

export default defineComponent({
  components: {
    ErrorIndicator,
  },
  props: {
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: "An error has occurred",
    },
  },
});
