import { QueryParams } from "./http";

const DESCENDING_ORDER_PREFIX = "-";

export interface Pagination {
  sortBy?: string;
  descending: boolean;
  page: number;
  rowsPerPage: number;
  rowsNumber?: number;
}

const DEFAULT_PAGINATION: Pagination = {
  descending: false,
  page: 1,
  rowsPerPage: 15,
  rowsNumber: 0,
};

export const DEFAULT_ROWS_PER_PAGE = [5, 15, 25, 50, 100];

export function buildOrderingQueryParamsFromPagination(
  pagination: Pagination
): QueryParams {
  // check for null too as Quasar has a typing bug:
  // https://github.com/quasarframework/quasar/issues/12605
  if (pagination.sortBy === null || pagination.sortBy === undefined) {
    return {};
  }

  const orderByPrefix = pagination.descending ? DESCENDING_ORDER_PREFIX : "";
  return { order: orderByPrefix + pagination.sortBy };
}

export function buildQueryParamsFromPagination(
  pagination: Pagination
): QueryParams {
  return {
    ...buildOrderingQueryParamsFromPagination(pagination),
    limit: String(pagination.rowsPerPage),
    offset: String(Math.max((pagination.page - 1) * pagination.rowsPerPage, 0)),
  };
}

export function buildPaginationFromQueryParams(
  queryParams: QueryParams,
  customDefaults: Partial<Pagination> = {}
): Pagination {
  const pagination = { ...DEFAULT_PAGINATION, ...customDefaults };

  if (typeof queryParams["order"] === "string") {
    const order = queryParams["order"];
    if (order.startsWith(DESCENDING_ORDER_PREFIX)) {
      pagination.descending = true;
      pagination.sortBy = queryParams["order"].replace(
        DESCENDING_ORDER_PREFIX,
        ""
      );
    } else {
      pagination.descending = false;
      pagination.sortBy = queryParams["order"];
    }
  }
  if (typeof queryParams["limit"] === "string") {
    const limit = Number(queryParams["limit"]);
    if (Number.isInteger(limit) && limit >= 0) {
      pagination.rowsPerPage = Number(queryParams["limit"]);
    }
  }
  if (typeof queryParams["offset"] === "string") {
    const offset = Number(queryParams["offset"]);
    if (Number.isInteger(offset) && offset >= 0) {
      const page =
        Math.floor(Number(queryParams["offset"]) / pagination.rowsPerPage) + 1;
      if (Number.isInteger(page)) {
        pagination.page = page;
      }
    }
  }

  return pagination;
}

export const TEST_ONLY = {
  DEFAULT_PAGINATION,
};
