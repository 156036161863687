import * as t from "io-ts";

export const LottieMetadataGuard = t.type({
  id: t.number,
  name: t.string,
  url: t.string,
});

export const LottieResponseGuard = t.type({
  results: t.array(LottieMetadataGuard),
  next: t.union([t.string, t.null]),
});

export type LottieMetadata = t.TypeOf<typeof LottieMetadataGuard>;
export type LottieResponse = t.TypeOf<typeof LottieResponseGuard>;
