import debounce from 'quasar/src/utils/debounce.js';import QForm from 'quasar/src/components/form/QForm.js';;
import { computed, ref, Ref, watch } from "vue";

import { InAppFilters, InAppStatus, InAppType } from "@/api/inapps";
import { getUpdateSingleTagListener } from "@/api/tags/helpers";
import { isValidRangeFilterFormat } from "@/lib/filters";

interface UseInAppsFiltersOptions {
  filters: Ref<InAppFilters>;
  onFiltersChange: (filters: InAppFilters) => void;
}

export function useInAppsFilters({
  filters,
  onFiltersChange,
}: UseInAppsFiltersOptions) {
  const filtersForm: Ref<QForm | null> = ref(null);

  const name: Ref<string | null> = ref(filters.value.name);
  const status: Ref<InAppStatus | null> = ref(filters.value.status);
  const id: Ref<string | null> = ref(filters.value.id);
  const inAppType: Ref<InAppType | null> = ref(filters.value.inAppType);
  const createdBy: Ref<string | null> = ref(filters.value.createdBy);
  const tagId: Ref<number | null> = ref(filters.value.tag);

  const emitFiltersChange = debounce(async () => {
    const isValid = await filtersForm.value?.validate();
    if (!isValid) return;

    onFiltersChange({
      name: name.value,
      status: status.value,
      id: id.value,
      inAppType: inAppType.value,
      createdBy: createdBy.value,
      tag: tagId.value,
    });
  }, 500);

  const clearAllFilters = () => {
    name.value = null;
    status.value = null;
    id.value = null;
    inAppType.value = null;
    createdBy.value = null;
    tagId.value = null;
  };

  const updateTagId = getUpdateSingleTagListener(tagId);

  const isClearButtonVisible = computed(() => {
    return (
      Object.values(filters.value).find((val) => val !== null && val !== "") !==
      undefined
    );
  });

  watch([name, status, id, inAppType, createdBy, tagId], () =>
    emitFiltersChange()
  );

  return {
    name,
    status,
    id,
    type: inAppType,
    createdBy,
    tagId,
    updateTagId,
    filtersForm,
    clearAllFilters,
    isClearButtonVisible,
    isValidRangeFilterFormat,
  };
}
