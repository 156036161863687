
import { defineComponent } from "vue";

export default defineComponent({
  emits: {
    openClick: () => true,
    openNewTabClick: () => true,
    copyToClipboardClick: () => true,
  },
  setup(props, { emit }) {
    const handleOpenClick = () => emit("openClick");
    const handleOpenNewTabClick = () => emit("openNewTabClick");
    const handleCopyToClipboardClick = () => emit("copyToClipboardClick");

    return {
      handleOpenClick,
      handleOpenNewTabClick,
      handleCopyToClipboardClick,
    };
  },
});
