import * as t from "io-ts";

// GUARDS
export const InAppButtonActionTypeGuard = t.keyof({
  delete_sd: t.literal("delete_sd"),
  report_sd: t.literal("report_sd"),
  url: t.literal("url"),
});

export const InAppButtonActionGuard = t.type({
  type: InAppButtonActionTypeGuard,
  value: t.union([t.string, t.null]),
});

// TYPES
export type InAppButtonActionType = t.TypeOf<typeof InAppButtonActionTypeGuard>;
export type InAppButtonAction = t.TypeOf<typeof InAppButtonActionGuard>;

// OPTIONS
interface InAppButtonActionTypeOption {
  value: InAppButtonActionType;
  label: string;
}

export const actionTypeLabel = (option: unknown) => {
  return (
    InAppButtonActionTypeOptions.find((o) => o.value === option)?.label ??
    "Unknown option selected"
  );
};

export const InAppButtonActionTypeOptions: InAppButtonActionTypeOption[] = [
  {
    label: "Delete SD",
    value: "delete_sd",
  },
  {
    label: "Report SD",
    value: "report_sd",
  },
  {
    label: "URL",
    value: "url",
  },
];
