import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_list_header = _resolveComponent("list-header")!

  return (_openBlock(), _createBlock(_component_list_header, { title: "In Apps" }, {
    default: _withCtx(() => [
      (_ctx.addButtonVisible)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            icon: "add",
            color: "primary",
            "data-test": "ia.header.button.add",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addNewInApp')))
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Create new message")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}