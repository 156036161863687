import { InAppFilters, InAppStatusGuard } from "@/api/inapps/typedefs/inApp";
import { InAppTypeGuard } from "@/api/inapps/typedefs/inAppTypes";
import { isValidRangeFilterFormat } from "@/lib/filters";
import { QueryParams } from "@/lib/http";
import { cast } from "@/lib/typing";

const INITIAL_FILTERS: InAppFilters = {
  name: null,
  status: null,
  id: null,
  createdBy: null,
  inAppType: null,
  tag: null,
};

export function buildInAppQueryParamsFromFilters(
  filters: InAppFilters
): Record<string, string> {
  const params: Record<string, string> = {};

  if (filters.name !== null) {
    params["name"] = filters.name;
  }

  if (filters.status !== null) {
    params["status"] = filters.status;
  }

  if (filters.id !== null) {
    params["id"] = filters.id;
  }

  if (filters.inAppType !== null) {
    params["in_app_type"] = filters.inAppType;
  }

  if (filters.createdBy !== null) {
    params["created_by"] = filters.createdBy;
  }

  if (filters.tag !== null) {
    params["tag"] = String(filters.tag);
  }

  return params;
}

export function buildInAppFiltersFromQueryParams(
  queryParams: QueryParams
): InAppFilters {
  const filters = { ...INITIAL_FILTERS };

  if (typeof queryParams["name"] === "string") {
    filters.name = queryParams["name"];
  }

  if (typeof queryParams["status"] === "string") {
    try {
      filters.status = cast(InAppStatusGuard, queryParams["status"]);
    } catch (error) {
      // Ignore type error.
    }
  }

  if (typeof queryParams["id"] === "string") {
    const id = String(queryParams["id"]);
    if (isValidRangeFilterFormat(id)) {
      filters.id = id;
    }
  }

  if (typeof queryParams["in_app_type"] === "string") {
    try {
      filters.inAppType = cast(InAppTypeGuard, queryParams["in_app_type"]);
    } catch (error) {
      // Ignore type error.
    }
  }

  if (typeof queryParams["created_by"] === "string") {
    filters.createdBy = queryParams["created_by"];
  }

  if (typeof queryParams["tag"] === "string") {
    const tagId = parseInt(queryParams["tag"]);
    if (!isNaN(tagId)) {
      filters.tag = tagId;
    }
  }

  return filters;
}

export const TEST_ONLY = {
  INITIAL_FILTERS,
};
