
import type { QTableProps } from "quasar";
import { defineComponent, PropType } from "vue";

import { ExistingInApp, InAppMetadata } from "@/api/inapps";
import ListContent from "@/components/shared/list/ListContent.vue";
import RowContextMenu from "@/components/shared/rowcontextmenu/RowContextMenu.vue";
import { DEFAULT_ROWS_PER_PAGE, Pagination } from "@/lib/pagination";
import { getStatusColor } from "@/lib/status";

function getIsRuntimeColor(value: boolean): string {
  return value ? "positive" : "negative";
}

const columns: QTableProps["columns"] = [
  {
    name: "id",
    label: "ID",
    align: "left",
    sortable: true,
    field: (row: ExistingInApp) => row.id,
  },
  {
    name: "status",
    label: "Status",
    align: "left",
    sortable: true,
    field: (row: ExistingInApp) => row.status,
  },
  {
    name: "name",
    label: "Name",
    align: "left",
    sortable: true,
    field: (row: ExistingInApp) => row.name,
  },
  {
    name: "in_app_type",
    label: "Type",
    align: "left",
    sortable: true,
    field: (row: ExistingInApp) => row.inAppType,
  },
  {
    name: "created_by",
    label: "Created By",
    align: "left",
    sortable: true,
    field: (row: ExistingInApp) => row.createdBy,
  },
  {
    name: "created_at",
    label: "Created At",
    align: "left",
    sortable: true,
    field: (row: ExistingInApp) => row.createdAt,
  },
  {
    name: "start_time",
    label: "Start Time",
    align: "left",
    sortable: true,
    field: (row: ExistingInApp) => row.startTime,
  },
  {
    name: "end_time",
    label: "End Time",
    align: "left",
    sortable: true,
    field: (row: ExistingInApp) => row.endTime,
  },
  {
    name: "is_runtime",
    label: "Runtime",
    align: "left",
    sortable: false,
    field: (row: ExistingInApp) => row.isRuntime,
  },
];

export default defineComponent({
  components: {
    RowContextMenu,
    ListContent,
  },
  props: {
    inApps: {
      type: Object as PropType<InAppMetadata[]>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    paginationChange: (pagination: Pagination) =>
      typeof pagination === "object",
    rowClick: (inApp: InAppMetadata) => typeof inApp === "object",
    rowMiddleClick: (inApp: InAppMetadata) => typeof inApp === "object",
    rowMenuOpenClick: (inApp: InAppMetadata) => typeof inApp === "object",
    rowMenuOpenNewTabClick: (inApp: InAppMetadata) => typeof inApp === "object",
    rowMenuClipboardCopyClick: (inApp: InAppMetadata) =>
      typeof inApp === "object",
    exportToCsvClick: () => true,
  },
  setup(props, { emit }) {
    const onRequest = (payload: { pagination: Pagination }) => {
      emit("paginationChange", payload.pagination);
    };

    const onPaginationChange = (pagination: Pagination) => {
      emit("paginationChange", pagination);
    };

    const handleRowClick = (event: MouseEvent, row: InAppMetadata) =>
      emit("rowClick", row);

    const handleMiddleRowClick = (event: MouseEvent, row: InAppMetadata) =>
      emit("rowMiddleClick", row);

    const handleMenuOpenClick = (event: MouseEvent, row: InAppMetadata) =>
      emit("rowMenuOpenClick", row);

    const handleMenuOpenNewTabClick = (
      event: PointerEvent,
      row: InAppMetadata
    ) => emit("rowMenuOpenNewTabClick", row);

    const handleCopyToClipboardClick = (
      event: PointerEvent,
      row: InAppMetadata
    ) => emit("rowMenuClipboardCopyClick", row);

    const handleExportToCsvClick = () => emit("exportToCsvClick");

    return {
      DEFAULT_ROWS_PER_PAGE,
      columns,
      onRequest,
      onPaginationChange,
      getStatusColor,
      getIsRuntimeColor,
      // row click handlers
      handleRowClick,
      handleMiddleRowClick,
      // context menu handlers
      handleMenuOpenClick,
      handleMenuOpenNewTabClick,
      handleCopyToClipboardClick,
      // table handlers
      handleExportToCsvClick,
    };
  },
});
